var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('b-card',{staticClass:"py-2 py-lg-3 py-xl-5",attrs:{"no-body":"","no-header":""}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{staticClass:"order-2 order-md-1 mt-5 mt-md-0 ",attrs:{"md":"6"}},[_c('div',{staticClass:"px-3 px-md-2 px-lg-5 mx-0 mx-lg-2"},[_c('h4',[_vm._v("Update user")]),_c('validation-observer',{ref:"userUpdateForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateUser($event)}}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Full name","vid":"fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-fullname","name":"register-fullname","state":errors.length > 0 ? false : null,"placeholder":"Full Name"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"}},[_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":_vm.errorExists,"placeholder":"Username"},on:{"keyup":function($event){return _vm.checkUsername()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.checkUsernameError))])],1)],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","rules":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":"Password"},on:{"keyup":function($event){return _vm.checkPassword()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password-confirm","type":_vm.cPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"password-confirm","placeholder":"Confirm password"},on:{"keyup":function($event){return _vm.checkPassword()}},model:{value:(_vm.cPassword),callback:function ($$v) {_vm.cPassword=$$v},expression:"cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.cPasswordToggleIcon},on:{"click":_vm.toggleCPasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.btnDisabled}},[_vm._v(" Update user ")]),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Loading..."}})],1):_vm._e()],1)],1)],1)]),_c('b-col',{staticClass:"order-1 order-md-2",attrs:{"md":"6"}},[_c('div',{staticClass:"px-3 px-md-2 px-lg-5 mx-0 mx-lg-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v("Current subscription plan")]),_c('div',[(_vm.subscription.length > 0)?_c('div',[(_vm.subscription[0].stripe_price == _vm.productFullPackage)?_c('p',{staticClass:"font-weight-normal mb-0"},[_vm._v(" MHC Premium Plan ")]):_vm._e(),_c('small',[_vm._v("Started at: "+_vm._s(_vm._f("prepareDate")(_vm.subscription[0].created_at)))])]):_c('div',[_c('p',{staticClass:"font-weight-normal mb-0"},[_vm._v("Free")]),_c('small',[_vm._v("No subscription plan")])])]),_c('div',{staticClass:"mt-3"},[_c('label',{attrs:{"for":"free-questionnaires"}},[_vm._v("Free questionnaires "),(
                  _vm.subscription.length > 0 &&
                  _vm.subscription[0].stripe_price == _vm.productFullPackage
                )?_c('strong',[_vm._v("- Cannot give free questionnaire to premium users")]):_vm._e()]),_c('b-form-input',{attrs:{"id":"free-questionnaires","name":"free-questionnaires","type":"number","disabled":_vm.subscription.length > 0 &&
                _vm.subscription[0].stripe_price == _vm.productFullPackage},model:{value:(_vm.free_questionnaires),callback:function ($$v) {_vm.free_questionnaires=$$v},expression:"free_questionnaires"}})],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }