<template>
  <b-overlay :show="show" rounded="sm">
    <b-card no-body no-header class="py-2 py-lg-3 py-xl-5">
      <b-row class="match-height">
        <b-col md="6" class="order-2 order-md-1 mt-5 mt-md-0 ">
          <div class="px-3 px-md-2 px-lg-5 mx-0 mx-lg-2">
            <h4>Update user</h4>

            <!-- form -->
            <validation-observer ref="userUpdateForm">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="updateUser"
              >
                <!-- fullname -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Full name"
                    vid="fullname"
                    rules="required"
                  >
                    <b-form-input
                      id="register-fullname"
                      v-model="fullname"
                      name="register-fullname"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Full Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- username -->
                <b-form-group>
                  <validation-provider
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="register-username"
                      v-model="username"
                      name="register-username"
                      :state="errorExists"
                      placeholder="Username"
                      @keyup="checkUsername()"
                    />
                    <small class="text-danger">{{ checkUsernameError }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="userEmail"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="password"
                        placeholder="Password"
                        @keyup="checkPassword()"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password-confirm"
                        v-model="cPassword"
                        :type="cPasswordFieldType"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="password-confirm"
                        placeholder="Confirm password"
                        @keyup="checkPassword()"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="cPasswordToggleIcon"
                          class="cursor-pointer"
                          @click="toggleCPasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  class="mt-2"
                  variant="primary"
                  type="submit"
                  :disabled="btnDisabled"
                >
                  Update user
                </b-button>
                <div v-if="loading" class="d-flex justify-content-center mt-2">
                  <b-spinner variant="primary" label="Loading..." />
                </div>
              </b-form>
            </validation-observer>
          </div>
        </b-col>

        <b-col md="6" class="order-1 order-md-2">
          <div class="px-3 px-md-2 px-lg-5 mx-0 mx-lg-2">
            <h4 class="mb-1">Current subscription plan</h4>

            <div>
              <div v-if="subscription.length > 0">
                <p
                  class="font-weight-normal mb-0"
                  v-if="subscription[0].stripe_price == productFullPackage"
                >
                  MHC Premium Plan
                </p>
                <small
                  >Started at:
                  {{ subscription[0].created_at | prepareDate }}</small
                >
              </div>
              <div v-else>
                <p class="font-weight-normal mb-0">Free</p>
                <small>No subscription plan</small>
              </div>
            </div>
            <div class="mt-3">
              <label for="free-questionnaires"
                >Free questionnaires
                <strong
                  v-if="
                    subscription.length > 0 &&
                    subscription[0].stripe_price == productFullPackage
                  "
                  >- Cannot give free questionnaire to premium users</strong
                ></label
              >
              <b-form-input
                id="free-questionnaires"
                v-model="free_questionnaires"
                name="free-questionnaires"
                type="number"
                :disabled="
                  subscription.length > 0 &&
                  subscription[0].stripe_price == productFullPackage
                "
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import store from "@/store";
import {
  BCard,
  BOverlay,
  BButton,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
  },
  data() {
    return {
      show: true,
      fullname: "",
      username: "",
      userEmail: "",
      password: "",
      cPassword: "",

      free_questionnaires: 0,
      // validation
      required,
      email,

      btnDisabled: false,
      loading: false,
      isAvailableUsername: 1,
      errors: [],
      slugFlag: false,
      slugString: "",

      // Toggle Password
      passwordFieldType: "password",
      cPasswordFieldType: "password",

      // Products
      productFullPackage: store.state.app.productFullPackage,
      fullPackagePrice: store.state.app.fullPackagePrice,

      // Payment
      subscription: [],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    cPasswordToggleIcon() {
      return this.cPasswordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    checkUsernameError() {
      return this.errors[0];
    },
    errorExists() {
      if (this.username.length > 0) {
        if (this.isAvailableUsername == 0) {
          this.errors.push("That username already exists");
          return false;
        } else {
          this.errors = [];
          return null;
        }
      } else {
        this.errors.push("The Username field is required");
        return false;
      }
    },
  },
  mounted() {
    let id = this.$route.params.id;
    //Return permissions list
    this.getUserInfo(id);
  },
  methods: {
    getUserInfo(id) {
      this.$http
        .get("/api/auth/admin/users/" + id)
        .then((res) => {
          (this.fullname = res.data.name),
            (this.username = res.data.username),
            (this.userEmail = res.data.email),
            (this.subscription = res.data.subscriptions);
          // Hide overlay
          this.show = false;

          if (res.data.number_of_free_questionnaires != null) {
            this.free_questionnaires = res.data.number_of_free_questionnaires;
          }
        })
        .catch((err) => {
          console.log(err);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong, please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });

          // Hide overlay
          this.show = false;
        });
    },

    // Toggle password visibility
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    toggleCPasswordVisibility() {
      this.cPasswordFieldType =
        this.cPasswordFieldType === "password" ? "text" : "password";
    },

    // Request to update user
    updateUser() {
      // Show overlay
      this.show = true;

      // Get param from url
      let id = this.$route.params.id;

      let user = {
        name: this.fullname,
        email: this.userEmail,
        username: this.username,
        role: "user",
        number_of_free_questionnaires: this.free_questionnaires,
      };

      // If password is changed
      if (this.password.length > 0 && this.cPassword.length > 0) {
        user.password = this.password;
        user.c_password = this.cPassword;
      }

      this.$refs.userUpdateForm.validate().then((success) => {
        if (success) {
          this.btnDisabled = false;

          this.$http
            .patch("/api/auth/admin/users/" + id, user)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "User successfully updated",
                  icon: "CheckSquareIcon",
                  variant: "success",
                },
              });

              // Hide overlay
              this.show = false;

              let id = this.$route.params.id;
              this.getUserInfo(id);
            })
            .catch((error) => {
              // Hide overlay
              this.show = false;

              let errorMsg = "There was an error!";

              if (error.response != undefined) {
                if (error.response.data.errors != undefined) {
                  if (error.response.data.errors.email != undefined) {
                    errorMsg = error.response.data.errors.email[0];
                  } else {
                    errorMsg = error.response.data.errors.username[0];
                  }
                } else {
                  errorMsg = error.response.data.message;
                }
              } else {
                errorMsg = error.message;
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            });
        } else {
          this.btnDisabled = true;
        }
      });
    },

    checkUsername() {
      let username = this.username.trim();

      if (username != "" && !username.includes(" ")) {
        this.$http
          .get(`/api/user/username/${username}`)
          .then((res) => {
            this.isAvailableUsername = 1;
            this.errors = [];
          })
          .catch((error) => {
            this.isAvailableUsername = 0;
            if (error.response.data.message == "Username is taken") {
              this.errors[0] = "That username already exists";
            } else if (
              error.response.data.message == "The given data was invalid."
            ) {
              this.errors[0] = "Swear and bad words not allowed";
            } else {
              this.errors[0] = "Something is wrong, please try again.";
            }
          });
      }

      if (username.includes(" ")) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Username must not contain spaces!",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },

    checkPassword() {
      if (this.password.length > 0 || this.cPassword.length > 0) {
        if (this.password == this.cPassword) {
          this.btnDisabled = false;
        } else {
          this.btnDisabled = true;
        }
      } else {
        this.btnDisabled = false;
      }
    },
  },
};
</script>

<style>
.vs__selected {
  background-color: #59949c !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
